/// <reference path="../types.d.ts" />
import {
  syncActions,
  asyncActions,
  replaceOrganizationState
} from '../actions/organization';
import { uiActions, commonEnums as enums } from '@codametrix/ui-common';
import InitialState from '../reducers/initial-state';
import { EMPTY_PAYLOAD } from '../actions/_action-utilities';
import { inProgress } from '../actions/common';
import { actions } from '../actions/users';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { cmxReducerFactory } from './reducer-utils';
import { formErrors } from '../stubs/form-errors';

const { ORGS } = enums;

const childTypes: any = {};
childTypes[ORGS.BUSINESS] = new Set([ORGS.HEALTH_SYSTEM, ORGS.HOSPITAL]);
childTypes[ORGS.HEALTH_SYSTEM] = new Set([
  ORGS.PHYSICIAN_ORGANIZATION,
  ORGS.HOSPITAL
]);
childTypes[ORGS.HOSPITAL] = new Set([ORGS.PHYSICIAN_ORGANIZATION]);
childTypes[ORGS.PHYSICIAN_ORGANIZATION] = new Set();

function groupChildren(
  children: CMxAPI.Organization[],
  activeOrganization?: CMxAPI.Organization
): CMx.OrgChildren {
  const childSets: CMx.OrgChildren = {};
  const childOrgTypes: Set<string> = activeOrganization
    ? childTypes[activeOrganization.organizationType]
    : new Set(children.map(kid => kid.organizationType));
  [...Array.from(childOrgTypes)].forEach(orgType => (childSets[orgType] = []));

  children.forEach(child => {
    if (!childSets[child.organizationType]) {
      childSets[child.organizationType] = [];
    }
    if (!child.displayName) {
      child.displayName = child.organizationName;
    }
    childSets[child.organizationType].push(child);
  });

  [...Array.from(childOrgTypes)].forEach(orgType =>
    childSets[orgType].sort((org1, org2) =>
      org1.displayName!.localeCompare(org2.displayName!)
    )
  );

  return childSets;
}

function determineUserPath(
  state: CMx.OrganizationState,
  organization: CMxAPI.Organization
): CMxComponents.Breadcrumb[] {
  const idx = state.path.findIndex(
    crumb => crumb.data.tenantId === organization.tenantId
  );
  let path;

  if (idx !== -1) {
    path = state.path.slice(0, idx + 1);
    path[path.length - 1].display = false;
  } else {
    const current: CMxComponents.Breadcrumb = {
      link: false,
      display: false,
      data: organization,
      displayName: `${organization.displayName}`
    };
    const allLinks = state.path.map(crumb => {
      return { ...crumb, link: true, display: true };
    });
    path = [...allLinks, current];
  }

  return path;
}

const handleChildren = (
  state: CMx.OrganizationState,
  children: CMxAPI.Organization[]
) => ({
  ...state,
  organizations: groupChildren(children, state.activeOrganization)
});

const handleOrganizationSelected = (
  state: CMx.OrganizationState,
  organization: CMxAPI.Organization
) => {
  const editable =
    state.activeOrganization.id === organization.parentId ||
    state.activeOrganization.id === organization.id;
  const statePath = determineUserPath(state, organization);

  return {
    ...state,
    activeOrganization: organization,
    organizations: EMPTY_PAYLOAD,
    formErrors: formErrors.noErrors,
    editable,
    path: statePath,
    orgRelationships: [],
    associations: []
  };
};

export const reducer = cmxReducerFactory(InitialState.organization)
  .case(inProgress, state => ({
    ...state,
    formErrors: formErrors.noErrors
  }))
  .cases(
    [
      asyncActions.saveOrganization.async.failed,
      asyncActions.doAssociation.async.failed
    ],
    (state, { error }) => ({
      ...state,
      formErrors: error
    })
  )
  .case(syncActions.toggleEdit, (state, edit) => ({
    ...state,
    edit,
    addChild: false
  }))
  .case(syncActions.establishRelationship, (state, relationship) => ({
    ...state,
    relationship
  }))
  .case(syncActions.orgRelationships, (state, orgRelationships) => {
    const now = Date.now();

    const relationships = orgRelationships.filter(relationship => {
      if (relationship.endDate === null) return true;

      return relationship.endDate > now;
    });

    return {
      ...state,
      orgRelationships: relationships
    };
  })
  .case(syncActions.serviceLinesAvailable, (state, serviceLines) => ({
    ...state,
    serviceLines
  }))
  .case(syncActions.availableAssociations, (state, associations) => ({
    ...state,
    associations
  }))
  .case(syncActions.addChildOrgType, (state, addChild) => ({
    ...state,
    edit: false,
    addChild,
    formErrors: formErrors.noErrors
  }))
  .case(syncActions.associationCreated, (state, relationship) => ({
    ...state,
    edit: false,
    addChild: false,
    formErrors: formErrors.noErrors
  }))
  .case(syncActions.organizationChildren, handleChildren)
  .case(syncActions.organizationSelected, handleOrganizationSelected)
  .case(uiActions.chooseContext, (state, context) => {
    if (context.activeContext && state.path.length === 0) {
      return {
        ...state,
        activeOrganization: {
          ...state.activeOrganization,
          id: context.activeContext?.organizationId,
          organizationName:
            context.activeContext?.displayName ||
            state.activeOrganization.organizationName,
          tenantId:
            context.activeContext?.tenantId || state.activeOrganization.tenantId
        }
      };
    }

    return { ...state };
  })
  .case(syncActions.timezonesAvailable, (state, timezones) => ({
    ...state,
    timezones
  }))
  .case(syncActions.chooseDiagram, (state, selectedDiagram) => {
    return {
      ...state,
      selectedDiagram
    };
  })
  .case(asyncActions.getFeaturesBySection.async.done, (state, { result }) => {
    return {
      ...state,
      features: result ?? []
    };
  })
  .case(replaceOrganizationState, (state, newState) => {
    return { ...state, ...newState };
  })
  .case(actions.availableOrgs, (state, org) => {
    return {
      ...state,
      activeOrganization: org
    };
  });

export default reducer;
