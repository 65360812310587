import { LOAD_STATES } from '../common';
import {
  columnDefinitionMetadata,
  dictionaryMetadata
} from '../../tests/fixtures/stubs/dictionary';
import { CMxComponents } from '@codametrix/ui-components/dist/types';
import { formErrors } from '../../stubs/form-errors';

const ColumnNameField: CMxCommonApp.FieldDefinition = {
  label: `Column Name`,
  key: `columnName`,
  helpText: 'The name of the column that is being mapped',
  placeholder: '',
  type: 'text',
  readonly: true
};

const Tenant = {
  label: `Tenant`,
  key: `referenceTenant`,
  name: `referenceTenant`,
  type: 'select',
  placeholder: 'Choose a Tenant',
  required: false,
  labelField: 'tenantName',
  valueField: 'key',
  value: '',
  options: [],
  helpText: 'The Tenant that the reference dictionary belongs to'
};

const Dictionary = {
  label: `Dictionary`,
  key: `referenceDictionary`,
  name: `referenceDictionary`,
  type: 'select',
  required: false,
  labelField: 'name',
  valueField: 'name',
  placeholder: 'Choose a Dictionary',
  value: '',
  options: [],
  helpText: 'The name of the reference dictionary'
};

const Columns = {
  label: `Column`,
  key: `referenceColumn`,
  name: `referenceColumn`,
  type: 'select',
  required: false,
  labelField: 'name',
  valueField: 'name',
  placeholder: 'Choose a Column',
  options: [],
  helpText: 'The name of the reference column'
};

const isRefValueList = {
  label: 'Value List',
  key: 'isRefValueList',
  name: 'isReferenceValueList',
  type: 'checkbox',
  required: false,
  labelField: 'isRefValueList',
  valueField: 'isRefValueList',
  helpText: 'Does this column contain a list of mapped values?'
};

const textEditable = {
  label: 'Text Box Editable',
  key: 'textEditable',
  name: 'isTextEditable',
  type: 'checkbox',
  required: false,
  labelField: 'textEditable',
  valueField: 'textEditable',
  helpText:
    'Does this column need to overwrite mapped column lookup in dictionary editing?'
};

export const defaultColMetadataForm: CMxComponents.FormDefintion = {
  fields: [
    ColumnNameField,
    Tenant,
    Dictionary,
    Columns,
    isRefValueList,
    textEditable
  ],
  buttons: [
    {
      className: 'qa-add-button',
      buttonType: 'submit',
      buttonText: 'Save'
    },
    { buttonType: 'link', buttonText: 'back' }
  ]
};

export const colMetadataDataItem = {
  keyColumnIndicator: false,
  dictionaryName: ''
};

export const defaultRequestError: CMxAPI.DictionaryRequestErrorDetails = {
  errorType: '',
  errorMessage: '',
  columnInError: '',
  valueInError: '',
  mappedDictionaryName: '',
  mappedDictionaryLookupColumn: '',
  mappedDictionaryTenantId: '',
  mappedDictionaryOrganizationName: '',
  mappedParentDictionaryOrganizationName: '',
  requestId: null
};

export const defaultUploadResponse: CMxAPI.DictionaryRequestResponse = {
  id: -1,
  tenantId: {
    uuid: ''
  },
  healthSystemTenantId: null,
  dictionaryName: null,
  requestType: null,
  requestStatus: null,
  errorStatus: false,
  errorMessage: null,
  dictionaryId: null,
  dictionaryCsvS3Uri: null,
  rowsProcessed: null,
  errors: [defaultRequestError]
};

export const dictionaryRequest: CMx.DictionaryRequestState = {
  formErrors: formErrors.noErrors,
  loadState: LOAD_STATES.initial,
  colMetadataLoadState: LOAD_STATES.initial,
  dictionaryMetadata: dictionaryMetadata,
  columnMetadataFormObj: columnDefinitionMetadata,
  columnMetadataForm: defaultColMetadataForm,
  requestResponse: defaultUploadResponse,
  isUploadCreate: true,
  viewOnly: true,
  selectedTenantName: ''
};
