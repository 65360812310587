import { USER_STUB } from '../stubs/user';

export const StubContext: CMx.Context = {
  jwtToken: '',
  expiryTimeToLive: 600000,
  expiryDuration: 600000,
  activeContext: null,
  contexts: [],
  user: USER_STUB,
  activeContextId: null
};

const StubOrgContext: CMxAPI.OrganizationContext = {
  resources: [],
  displayName: '',
  organizationId: 1,
  organizationName: '',
  organizationCode: '',
  tenantId: '',
  parentLevel: 1,
  organizationType: '',
  serviceLines: []
};

export const ReadOnlyDictionaryContext: CMx.Context = {
  ...StubContext,
  activeContext: {
    ...StubOrgContext,
    resources: [
      {
        label: 'Dictionary',
        resourceName: 'dictionary',
        permissionLevel: 'READ_ONLY',
        resourceId: 1,
        type: '',
        parentId: 1,
        sequence: -1,
        serviceLines: ['*'],
        metadata: ''
      }
    ]
  }
};

export const FullAccessDictionaryContext: CMx.Context = {
  ...ReadOnlyDictionaryContext,
  activeContext: {
    ...StubOrgContext,
    resources: [
      {
        label: 'Dictionary',
        resourceName: 'dictionary',
        permissionLevel: 'FULL_ACCESS',
        resourceId: 1,
        type: '',
        parentId: 1,
        sequence: -1,
        serviceLines: ['*'],
        metadata: ''
      }
    ]
  }
};
