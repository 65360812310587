import React, { useEffect, useRef, useState } from 'react';
import { CodeBenchv2 } from '@codametrix/shared-views';
import { ServiceDesk } from './service-desk';
import { useSelector, useDispatch } from 'react-redux';
import {
  PatientEncounterGradeBenchColumnsv2,
  uiActions
} from '@codametrix/ui-common';
import { commonEnums } from '@codametrix/ui-common';

const BenchMode = commonEnums.BenchMode;

type DualComponentProps = {
  detailProps: any;
  detailMethods: any;
  listProps: any;
  listMethods: any;
  match: any;
};

const ServiceDeskAndGradeBench = (props: DualComponentProps) => {
  const { params = {} } = props.match ?? {};

  const { UIView } = useSelector((state: CMx.ApplicationState) => {
    return state.ampUI;
  });
  const dispatch = useDispatch();
  const childRef = useRef<any>(null);

  const codeBenchPanelData: Accelerate.CodeBenchPanelData = {
    ...props.detailProps,
    capabilities: {
      ...props.detailProps.capabilities,
      capabilities: {
        ...props.detailProps.capabilities.capabilities,
        attachDocs: { on: false },
        improvements: { on: false },
        coderNotes: { on: false },
        versionProcess: { on: false },
        statusDescription: { on: false },
        submitCase: { on: false },
        actionMenu: { on: false }
      }
    },
    propertyConfig: PatientEncounterGradeBenchColumnsv2,
    gradeableInvoices: props.detailProps.gradeableInvoices
  };

  const detailProps = {
    UIView,
    ...codeBenchPanelData,
    ...props.detailMethods
  };

  const listProps = {
    ...props.listProps,
    ...props.listMethods
  };

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(true);

  const handleSelection = (item: CMxAPI.CaseRunV2) => {
    setIsDetailOpen(true);
  };

  useEffect(() => {
    if (params.id === undefined && childRef?.current?.refreshContent) {
      childRef.current.refreshContent();
    }
    setIsDetailOpen(params.id !== undefined);
  }, [params.id]);

  useEffect(() => {
    dispatch(uiActions.setAmplifyView());
    dispatch(uiActions.setStandaloneMode());
    return () => {
      dispatch(uiActions.setAutomateView());
    };
  }, [dispatch]);

  return (
    <>
      <div style={{ display: isDetailOpen ? 'none' : 'block' }}>
        {/* idk why are we rendering service desk */}
        <ServiceDesk
          {...listProps}
          childRef={childRef}
          onSelection={handleSelection}
        />
      </div>
      {isDetailOpen ? (
        <CodeBenchv2
          {...detailProps}
          match={props.match}
          benchMode={BenchMode.GRADE}
        />
      ) : null}
    </>
  );
};

export { ServiceDeskAndGradeBench };
