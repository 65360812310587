import { Flex, Grid } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../../components/button/button';
import { Checkbox } from '../../components/checkbox/checkbox';
import { DataGrid } from '../../components/data-grid/data-grid';
import Input from '../../components/input/input';
import { Select } from '../../components/select/select';
import { ButtonSize, ButtonVariant } from '../../core/enums';
import theme from '../../theme';
import { SubHeader } from '../organizations/subheader';
import {
  ACCOUNT_INFORMATION,
  ACTIVE,
  CHANGE_PASSWORD,
  GridRowData,
  FORCE_PASSWORD_CHANGE,
  LOCKED,
  OrganizationOptions,
  OrgOption,
  PasswordModalData,
  PASSWORD_CHANGE_DESC,
  PASSWORD_OPTIONS,
  RoleOption,
  RoleOptions,
  ROLES
} from './user-detail-constants';
import { styles } from './user-detail-styles';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { DeleteIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { ModalComponent } from '../../components/Modal/modal';
import EventEmitter from 'events';

const gridOptions: GridOptions = {
  suppressHorizontalScroll: true,
  suppressPaginationPanel: true
};

export const UserDetail: React.FC<AppProps.UserDetailProps> = props => {
  const {
    availableOrganizations,
    availableRoles,
    dispatchNavigation,
    save,
    formErrors,
    errors,
    selectedUser,
    editPermission,
    passwordModalOpen,
    ssoConfiguration,
    togglePasswordModal,
    toggleShowErrors
  } = props;
  const eventEmitter = useRef(new EventEmitter());
  const roleOptions: Array<RoleOptions> = [];
  const organizationOptions: Array<OrganizationOptions> = [];

  availableRoles?.forEach(item => {
    const option = { value: item?.id, label: item?.roleName };
    roleOptions.push(option);
  });

  availableOrganizations?.forEach(item => {
    const option = {
      value: item?.tenantId,
      label: item?.displayName ?? ''
    };
    organizationOptions.push(option);
  });

  const [selectedRole, setSelectedRole] = useState<RoleOption>();
  const [selectedOrg, setSelectedOrg] = useState<OrgOption>();
  const [forcePasswordChange, setNewPasswordChange] = useState(true);
  const [rowData, setRowData] = useState<Array<GridRowData>>([]);
  const [newSelectedUser, setNewSelectedUser] = useState({
    ...props.selectedUser
  });
  const [modalPassword, setNewModalPassword] = useState<PasswordModalData>({
    password: '',
    confirmPassword: ''
  });
  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  const toggleForcePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordChange(!forcePasswordChange);
    setNewSelectedUser({
      ...newSelectedUser,
      forcePasswordChange: !forcePasswordChange
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const { value, name } = target;
    setNewSelectedUser({ ...newSelectedUser, [name]: value });
  };

  const handleModalInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const { value, name } = target;
    setNewModalPassword({ ...modalPassword, [name]: value });
  };

  const onRoleSelect = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const { value, textContent } = target;
    setSelectedRole({ id: parseInt(value), roleName: textContent ?? '' });
  };

  const onOrganizationSelect = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const { value, textContent } = target;
    setSelectedOrg({ orgName: textContent ?? '', tenantId: value });
  };

  const onAddClick = () => {
    const existingRoles: CMxAPI.UserRoles[] = [...newSelectedUser.roles];
    const index = Object.entries(existingRoles)
      .map(item => item[1].tenantId)
      .indexOf(selectedOrg?.tenantId as string);
    const currentRole = availableRoles?.filter(item => {
      return item.id === selectedRole?.id;
    });
    if (index >= 0) {
      const existingOrg = Object.entries(existingRoles)[index][1];
      existingOrg?.roles.push(currentRole[0]);
      const newEntry: GridRowData = {
        organizationName: existingOrg?.organizationName,
        tenantId: existingOrg.tenantId,
        displayName: existingOrg.displayName
          ? existingOrg.displayName
          : existingOrg.organizationName,
        ...currentRole[0]
      };
      setRowData([...rowData, newEntry]);
    } else {
      const currentOrg = availableOrganizations.filter(item => {
        return item.tenantId === selectedOrg?.tenantId;
      });
      if (currentOrg && currentRole) {
        const role: CMxAPI.UserRoles = {
          tenantId: currentOrg[0]?.tenantId,
          organizationName: currentOrg[0]?.organizationName,
          roles: currentRole,
          displayName: currentOrg[0]?.displayName || ''
        };
        existingRoles.push(role);
      }
      const newEntry: GridRowData = {
        organizationName: currentOrg[0]?.organizationName,
        tenantId: currentOrg[0].tenantId,
        displayName: currentOrg[0].displayName
          ? currentOrg[0].displayName
          : currentOrg[0].organizationName,
        ...currentRole[0]
      };
      setRowData([...rowData, newEntry]);
    }
    setNewSelectedUser({ ...newSelectedUser, roles: [...existingRoles] });
  };

  const addButtonStatus = () => {
    if (
      selectedRole?.roleName?.trim().length &&
      selectedOrg?.orgName.trim().length
    ) {
      const temp = rowData.filter(item => {
        return (
          item.tenantId === selectedOrg.tenantId && item.id === selectedRole.id
        );
      });
      if (temp.length > 0) {
        return true;
      }
      return false;
    }
    return true;
  };

  const _handleFormSubmit = () => {
    if (newSelectedUser.ssoIndicator) {
      save(props.auth, {
        ...newSelectedUser,
        password: null,
        forcePasswordChange: false
      });
    } else {
      save(props.auth, newSelectedUser);
    }
  };

  const _handleChangePasswordSubmit = () => {
    save(props.auth, {
      ...newSelectedUser,
      password: modalPassword.password,
      confirmPassword: modalPassword.confirmPassword
    });
  };

  const saveButtonStatus = () => {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      roles,
      ssoIndicator
    } = newSelectedUser;
    if (isNewUser) {
      const passwordOrSSO =
        (password?.trim().length && confirmPassword?.trim().length) ||
        ssoIndicator;
      if (
        firstName.trim().length &&
        lastName.trim().length &&
        passwordOrSSO &&
        roles.length > 0 &&
        rowData.length > 0
      ) {
        return false;
      }
    } else {
      if (
        firstName.trim().length &&
        lastName.trim().length &&
        roles.length > 0 &&
        rowData.length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const modalSubmitStatus = () => {
    if (
      modalPassword.password.trim().length > 0 &&
      modalPassword.confirmPassword.trim().length > 0
    ) {
      return false;
    }
    return true;
  };

  const buildRowData = (roles: CMxAPI.UserRoles[]) => {
    const newData: Array<GridRowData> = [];
    roles.forEach(org => {
      org.roles.forEach(role => {
        newData.push({
          organizationName: org.organizationName,
          tenantId: org.tenantId,
          displayName: org.displayName ? org.displayName : org.organizationName,
          ...role
        });
      });
    });
    setRowData(newData);
  };

  const hasError = (key: string, errors: any) => {
    return errors && key in errors;
  };

  const getInlineError = (errors: string[]) => {
    return errors.join(' ');
  };

  const displayErrorIfPresent = (
    key: string,
    errors: CMxCommonApp.FieldErrors
  ) => {
    return hasError(key, errors) ? getInlineError(errors[key]) : '';
  };

  const roleErrors = Object.entries(formErrors?.fieldErrors).map(
    ([field, message]) => {
      return field === 'roles' ? message : null;
    }
  );

  const clearModalErrors = () => {
    props.toggleShowErrors(false);
  };

  const setPasswordModalStatus = (isOpen: boolean) => {
    setNewModalPassword({ password: '', confirmPassword: '' });
    togglePasswordModal(isOpen);
  };

  const handleDeleteRole = (field: ICellRendererParams) => {
    const { data } = field;
    const index = rowData.findIndex(
      item => item.tenantId === data.tenantId && item.roleName === data.roleName
    );
    if (index >= 0) {
      const selectedUserRoles = [...newSelectedUser.roles];
      const selectedUserIndex = selectedUserRoles.findIndex(
        item => item.tenantId === data.tenantId
      );
      if (selectedUserIndex >= 0) {
        if (selectedUserRoles[selectedUserIndex].roles.length > 1) {
          const roleIndex = selectedUserRoles[
            selectedUserIndex
          ].roles.findIndex(item => item.roleName === data.roleName);
          if (roleIndex >= 0) {
            selectedUserRoles[selectedUserIndex].roles.splice(roleIndex, 1);
          }
        } else {
          selectedUserRoles.splice(selectedUserIndex, 1);
        }
        const newRowData = [...rowData];
        newRowData.splice(index, 1);
        setRowData([...newRowData]);
        setNewSelectedUser({
          ...newSelectedUser,
          roles: [...selectedUserRoles]
        });
      }
    }
  };

  useEffect(() => {
    const isNew = selectedUser.id === null;
    toggleShowErrors(false);
    togglePasswordModal(false);
    setIsNewUser(isNew);
    if (isNew) {
      setNewSelectedUser({ ...selectedUser, forcePasswordChange: true });
    } else if (selectedUser.roles) {
      buildRowData(selectedUser.roles);
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  const colDefs = [
    {
      headerName: 'Role',
      field: 'roleName'
    },
    { headerName: 'Organization', field: 'organizationName' },
    {
      headerName: 'Remove',
      field: 'delete',
      cellRenderer: (field: any) => {
        return (
          <DeleteIcon
            onClick={event => eventEmitter.current.emit('deleteClick', field)}
          />
        );
      }
    }
  ];

  eventEmitter.current.once('deleteClick', evnt => {
    handleDeleteRole(evnt);
  });

  const classes = styles();

  return (
    <Grid sx={classes.root}>
      <SubHeader
        title={isNewUser ? 'Add User' : 'Edit User'}
        href={'/cmx/admin/users/'}
        handleAnchorElementClick={dispatchNavigation}
        anchorButtonVariant={ButtonVariant.LINKS}
        anchorButtonName={'Cancel'}
        actionName={'Save'}
        onActionClick={_handleFormSubmit}
        isActionDisabled={saveButtonStatus()}
        className={classes.text}
      />
      <Grid sx={classes.fieldsets}>
        <fieldset style={classes.fieldset}>
          <legend style={classes.legend}>{ACCOUNT_INFORMATION}</legend>
          <Flex sx={classes.accountinformation}>
            <Input
              placeholder={'Eg:name@example.com'}
              isRequired={true}
              textInfo={'The email address of the user.'}
              onChange={handleInputChange}
              label={'Username'}
              name={'username'}
              textError={displayErrorIfPresent(
                'username',
                formErrors.fieldErrors
              )}
              dataTestId={'userNameInput'}
              isError={hasError('username', formErrors.fieldErrors)}
              isReadOnly={!isNewUser}
              value={newSelectedUser.username}
            />
            {!isNewUser && (
              <>
                {editPermission && (
                  <Flex style={classes.checkBox}>
                    <div style={classes.checkBoxWidth}>
                      <p>
                        {ACTIVE}{' '}
                        <span style={{ color: theme.colors.success[500] }}>
                          {' '}
                          *
                        </span>
                      </p>
                    </div>
                    <Checkbox
                      label={''}
                      isChecked={newSelectedUser.active}
                      name={'activeCheckBox'}
                      dataTestId={'activeCheckBox'}
                      onChange={() => {
                        setNewSelectedUser({
                          ...newSelectedUser,
                          active: !newSelectedUser.active
                        });
                      }}
                    />
                  </Flex>
                )}
                <Flex style={classes.checkBox}>
                  <div style={classes.checkBoxWidth}>
                    <p>{LOCKED} </p>
                  </div>
                  <Checkbox
                    label={''}
                    isChecked={newSelectedUser.locked}
                    name={'activeLocked'}
                    dataTestId={'lockedCheckBox'}
                    onChange={() => {
                      setNewSelectedUser({
                        ...newSelectedUser,
                        locked: !newSelectedUser.locked
                      });
                    }}
                  />
                </Flex>
              </>
            )}
            {(editPermission || ssoConfiguration) && (
              <Flex style={classes.checkBox}>
                <div style={classes.checkBoxWidth}>
                  <p>
                    SSO{' '}
                    <span style={{ color: theme.colors.success[500] }}> </span>
                  </p>
                </div>
                <Checkbox
                  label={''}
                  isChecked={newSelectedUser.ssoIndicator}
                  name={'ssoCheckBox'}
                  dataTestId={'ssoCheckBox'}
                  onChange={() => {
                    setNewSelectedUser({
                      ...newSelectedUser,
                      ssoIndicator: !newSelectedUser.ssoIndicator
                    });
                  }}
                />
              </Flex>
            )}
            <Input
              isRequired={true}
              onChange={handleInputChange}
              label={'First Name'}
              placeholder={'Enter first name'}
              name={'firstName'}
              textError={displayErrorIfPresent(
                'firstName',
                formErrors.fieldErrors
              )}
              dataTestId={'firstNameInput'}
              isError={hasError('firstName', formErrors.fieldErrors)}
              value={newSelectedUser.firstName}
            />
            <Input
              isRequired={true}
              onChange={handleInputChange}
              label={'Last Name'}
              placeholder={'Enter last name'}
              name={'lastName'}
              textError={displayErrorIfPresent(
                'lastName',
                formErrors.fieldErrors
              )}
              dataTestId={'lastNameInput'}
              isError={hasError('lastName', formErrors.fieldErrors)}
              value={newSelectedUser.lastName}
            />
            {isNewUser && (
              <>
                <Input
                  isRequired={true}
                  onChange={handleInputChange}
                  label={'Password'}
                  placeholder={'Enter password'}
                  type={'password'}
                  name={'password'}
                  textError={displayErrorIfPresent(
                    'password',
                    formErrors.fieldErrors
                  )}
                  dataTestId={'passwordInput'}
                  isError={hasError('password', formErrors.fieldErrors)}
                  isDisabled={newSelectedUser.ssoIndicator}
                />
                <Input
                  isRequired={true}
                  onChange={handleInputChange}
                  label={'Confirm'}
                  placeholder={'Enter confirm password'}
                  type={'password'}
                  name={'confirmPassword'}
                  textError={displayErrorIfPresent(
                    'confirmPassword',
                    formErrors.fieldErrors
                  )}
                  dataTestId={'confirmPasswordInput'}
                  isError={hasError('confirmPassword', formErrors.fieldErrors)}
                  isDisabled={newSelectedUser.ssoIndicator}
                />
              </>
            )}
          </Flex>
        </fieldset>
        {isNewUser && !newSelectedUser.ssoIndicator && (
          <fieldset style={classes.fieldset}>
            <legend style={classes.legend}>{PASSWORD_OPTIONS}</legend>
            <Flex sx={classes.passwordoptions}>
              <h1>{FORCE_PASSWORD_CHANGE}</h1>
              <Checkbox
                label={''}
                dataTestId={'forcePasswordChangeCheckBox'}
                isChecked={forcePasswordChange}
                onChange={toggleForcePasswordChange}
                name="passwordCheckbox"
                isDisabled={newSelectedUser.ssoIndicator}
              />
              <p>{PASSWORD_CHANGE_DESC}</p>
            </Flex>
          </fieldset>
        )}
        <fieldset style={classes.fieldset}>
          <legend style={classes.legend}>
            {ROLES}
            <span style={classes.span}>*</span>
          </legend>
          <Grid sx={classes.roles}>
            {rowData.length > 0 && (
              <div>
                <DataGrid
                  rowData={rowData}
                  columnDefs={colDefs}
                  gridOptions={gridOptions}
                />
              </div>
            )}
            {!rowData.length && (
              <div className="no-roles-selected">
                There are no roles associated with this user. Add some using the
                form below.
              </div>
            )}
            <Grid
              templateColumns="0.15fr 1fr 0.0fr 1fr 0.2fr"
              gap={5}
              alignItems={'center'}
              marginTop={5}>
              <h1>Add as an</h1>
              <Select
                placeholder="choose role"
                items={roleOptions}
                value={selectedRole?.roleName}
                onChange={onRoleSelect}
                dataTestId={'roleSelect'}
              />
              <h1>at</h1>
              <Select
                placeholder="choose organization"
                items={organizationOptions}
                value={selectedOrg?.orgName}
                onChange={onOrganizationSelect}
                dataTestId={'organizationSelect'}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                label={'Add'}
                size={ButtonSize.XS}
                onClick={onAddClick}
                disabled={addButtonStatus()}
                height={'10'}
                width={'20'}
                name={'addRoleButton'}
                dataTestId={'addRoleButton'}
              />
            </Grid>
          </Grid>
        </fieldset>
        {!isNewUser && editPermission && !newSelectedUser.ssoIndicator && (
          <fieldset style={classes.fieldset}>
            <legend style={classes.legend}>{CHANGE_PASSWORD}</legend>
            <Flex flexDirection={'row'} alignItems={'center'} margin={'20'}>
              <Button
                label={'Change Password'}
                onClick={() => {
                  setPasswordModalStatus(true);
                }}
                name={'changePasswordButton'}
                dataTestId={'changePasswordButton'}
                isDisabled={newSelectedUser.ssoIndicator}
              />
            </Flex>
          </fieldset>
        )}
        {passwordModalOpen && !newSelectedUser.ssoIndicator && (
          <ModalComponent
            size={'xl'}
            isOpen={passwordModalOpen}
            handleClose={() => {
              setPasswordModalStatus(false);
            }}
            showCloseIcon={true}
            modalHeader={
              <div>Change Password for {newSelectedUser.username}</div>
            }
            modalContent={
              <Flex flexDirection={'column'} rowGap={6}>
                <Flex>
                  <Input
                    label={'New Password'}
                    onChange={handleModalInputChange}
                    placeholder={'Enter new password'}
                    name={'password'}
                    isRequired={true}
                    textError={displayErrorIfPresent(
                      'password',
                      formErrors.fieldErrors
                    )}
                    dataTestId={'newPasswordInput'}
                    type={'password'}
                    isError={hasError('password', formErrors.fieldErrors)}
                  />
                </Flex>
                <Flex>
                  <Input
                    label={'Confirm New Password'}
                    onChange={handleModalInputChange}
                    name={'confirmPassword'}
                    placeholder={'Enter confirm password'}
                    isRequired={true}
                    textError={displayErrorIfPresent(
                      'confirmPassword',
                      formErrors.fieldErrors
                    )}
                    dataTestId={'confirmNewPasswordInput'}
                    type={'password'}
                    isError={hasError(
                      'confirmPassword',
                      formErrors.fieldErrors
                    )}
                  />
                </Flex>
              </Flex>
            }
            modalFooter={
              <Flex alignItems={'flex-end'}>
                <Button
                  label={'Submit'}
                  onClick={_handleChangePasswordSubmit}
                  disabled={modalSubmitStatus()}
                  dataTestId={'passwordModalSubmitButton'}
                />
              </Flex>
            }
          />
        )}
        {errors ? (
          <ModalComponent
            isOpen={errors}
            handleClose={clearModalErrors}
            size="xl"
            modalHeader={
              <div>
                <WarningTwoIcon /> Error{' '}
              </div>
            }
            modalContent={
              <div>
                {Object.values(roleErrors).map(errorList => {
                  return errorList?.map(error => {
                    return <p key={`${error}`}>{error}</p>;
                  });
                })}
              </div>
            }
            modalFooter={
              <>
                <Button
                  label={'Return'}
                  onClick={clearModalErrors}
                  dataTestId={'errorModalReturnButton'}
                  name={'returnButton'}>
                  Secondary Action
                </Button>
              </>
            }
          />
        ) : null}
      </Grid>
    </Grid>
  );
};
