import { buildDispatchable } from '../../actions/_action-utilities';
import {
  updateUser,
  save,
  togglePasswordChangeModal,
  toggleShowErrors
} from '../../actions/users';
import * as dispatchNavigation from '../../actions/navigation';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { showFeedback } from '../../actions/ui';
import { commonActions } from '@codametrix/ui-common';
const { FeedbackLevel } = commonActions;

export const mapStateToProps = function(state: any) {
  const { users, ui, organization } = state;

  let adminTabs: CMxComponents.TabDef[] = ui.actionGroups
    .find((actionGroup: any) => {
      return actionGroup.key === 'admin';
    })
    ?.options[0].subActions.filter((tab: CMxComponents.TabDef) => {
      return ui.context.activeContext.resources.find((resource: any) => {
        return resource.resourceName === tab.resourceId;
      });
    });

  const ssoConfiguration: boolean =
    organization.activeOrganization?.ssoIndicator &&
    ui.context?.activeContext?.resources?.some((resource: any) => {
      return resource.resourceName === 'sso_configuration';
    });

  return {
    ...users,
    auth: state.login.auth,
    showHeader: !(adminTabs?.length > 1),
    ssoConfiguration
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    dispatchNavigation: dispatchNavigation.navExport(dispatch),
    save: async (token: CMxCommonApp.BearerToken, user: CMxAPI.User) => {
      runDispatchable(() => {
        return dispatch(
          save({
            user,
            token
          })
        );
      });
    },
    updateUser: async (token: CMxCommonApp.BearerToken, user: CMxAPI.User) => {
      runDispatchable(() => {
        return dispatch(
          updateUser({
            user,
            token
          })
        );
      });
    },
    togglePasswordModal: (isOpen: boolean) => {
      runDispatchable(() => {
        return dispatch(togglePasswordChangeModal(isOpen));
      });
    },
    toggleLockModal: (isLocked: boolean) => {
      runDispatchable(() => {
        return dispatch(
          showFeedback({
            id: Date.now(),
            dismissable: true,
            message: `This user will be ${
              isLocked ? 'locked' : 'unlocked'
            } on save`,
            meta: {
              level: FeedbackLevel.WARNING
            }
          })
        );
      });
    },
    toggleShowErrors: (errors: boolean) => {
      runDispatchable(() => {
        return dispatch(toggleShowErrors(errors));
      });
    }
  };
};
