/// <reference path="../types.d.ts" />

import { push } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import { api } from '../core/net';
import {
  caseUtils,
  commonActions,
  commonEnums as enums,
  getCodeRequests,
  abortController,
  appServiceLines,
  cmxTypeguards,
  cmxArtifacts,
  commonEnums,
  dictionaries,
  uiActions,
  api as commonApi,
  documentUtils,
  HttpMethods
} from '@codametrix/ui-common';
import { dismissFeedback, showFeedbackError, showFeedbackWorking } from './ui';
import { BROADCAST_ACTION_METADATA } from './action-constants';
import { isNumber } from '../core/type-guards';
import { objectUtils } from '@codametrix/ui-common';
import { gql } from '@apollo/client';
import { getServiceDeskClient } from '../clients/service-desk.client';
import { docMetadataAvailable, getDocuments } from './case-documents.actions';
import { AmplifyCore } from '@codametrix/ui-common';

const { uiStatus } = commonEnums;
const { paginationUtils } = AmplifyCore;

const { statusColors, statusBGColors } = enums;

const { getCaseRun } = caseUtils;
const { Case } = commonActions;
const { amplifyFeedback } = uiActions;
/** factories for actions */
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.CaseState>(actionCreator);

const featureSelection = actionCreator<CMxArtifacts.FeatureSelection>(
  Case.FEATURE_SELECTION
);

const featureActivation = actionCreator<CMxCommonApp.ChargeSessionSelection>(
  Case.FEATURE_ACTIVATION
);

const caseOrganizationContext = actionCreator<CMxAPI.Organization>(
  Case.ORGANIZATION_CONTEXT
);

const clearAllFilters = actionCreator<void>(Case.CLEAR_ALL_FILTERS);

const applyDefaultFilters = actionCreator<CMx.Context>(
  Case.APPLY_DEFAULT_FILTERS
);

const showProcessingTimeline = actionCreator<boolean>(Case.TOGGLE_TIMELINE);

const detailToggleArtifacts = actionCreator<boolean>(
  Case.DETAIL_TOGGLE_ARTIFACTS
);

const detailInitial = actionCreator<string>(Case.DETAIL_INIT);
const listInitial = actionCreator<CMxCommonApp.SortablePageable<any>>(
  Case.LIST_INIT
);
const caseSelected = actionCreator<CMxAPI.CaseRun>(Case.SELECTED);

const fetchInvoiceCodeDescriptions = actionCreator<CMxAPI.CodeResponse>(
  Case.INVOICE_CODE_DESCRIPTIONS
);

const setActiveNote = actionCreator<CMxCommonApp.DecoratedNote>(
  Case.SET_ACTIVE_NOTE
);

const setActiveProcess = createAsync<
  CMx.ActiveProcessPayload,
  any,
  CMxCommonApp.SubmitError
>(Case.SET_ACTIVE_PROCESS_ID, async (params, dispatch) => {
  if (isNumber(params.caseRun.billing_group_id)) {
    dispatch(
      getBillingGroup({
        billingGroupId: params.caseRun.billing_group_id,
        serviceLine: params.serviceLine
      })
    );
  }
  dispatch(amplifyProcessInstance(params.caseRun));
});

const list = createAsync<
  CMx.CaseListPayload,
  CMxAPI.PageableList<CMxAPI.Case>,
  CMxCommonApp.SubmitError
>(Case.LIST, async (params, dispatch) => {
  const { sortablePageable, serviceLine } = params;

  const serviceLineConfig = appServiceLines.determineServiceLineConfig(
    serviceLine
  );

  dispatch(listInitial(sortablePageable));
  dispatch(
    showFeedbackWorking({
      message: `Loading cases...`,
      dismissable: false
    })
  );
  const signal = abortController.getSignal(Case.LIST);

  const searchParams = paginationUtils.searchParamsFactory(sortablePageable);

  let body: any[] = [];

  if (sortablePageable.filterableOptions.filters) {
    body = sortablePageable.filterableOptions.filters;
    dispatch(applyFilters(sortablePageable.filterableOptions.filters));
  } else {
    dispatch(applyFilters([]));
  }

  if (sortablePageable.filterableOptions.dateFilter) {
    body = body.filter(filter => {
      return (
        filter.type !== 'GREATER_THAN_EQUAL' ||
        filter.type !== 'LESS_THAN_EQUAL'
      );
    });
    body.push({
      key: sortablePageable.filterableOptions.dateFilter.key,
      terms: [sortablePageable.filterableOptions.dateFilter.from],
      type: 'GREATER_THAN_EQUAL'
    });
    body.push({
      key: sortablePageable.filterableOptions.dateFilter.key,
      terms: [sortablePageable.filterableOptions.dateFilter.to],
      type: 'LESS_THAN_EQUAL'
    });
  }
  dispatch(
    applyDateFilter(
      sortablePageable.filterableOptions.dateFilter as CMxCommonApp.DateFilter
    )
  );

  const caseListPageable = await api<CMxAPI.PageableList<CMxAPI.Case>>({
    endpoint: `${
      serviceLineConfig.path
    }/caseinstance/list/v2?${searchParams.toString()}`,
    init: {
      method: 'POST',
      signal
    },
    body: body
  });

  dispatch(dismissFeedback(true));
  return caseListPageable;
});

const getDictionary = createAsync<
  AppProps.CategoryParams,
  any,
  CMxCommonApp.SubmitError
>(Case.DICTIONARY, async (params, dispatch) => {
  const dictionaryParams = new URLSearchParams([
    ['dictionaryName', params.categoryName]
  ]);

  const values = await api<CMxAPI.ProcedureTypeCategory[]>({
    endpoint: `/dictionary/dictionaryvalues/v1?${dictionaryParams.toString()}`
  });

  let FieldDefinitions: CMxCommonApp.FieldDefinition[] = [];

  if (values !== undefined) {
    values.forEach(criteria => {
      FieldDefinitions.push({
        label: undefined,
        key: criteria.abbreviation,
        helpText: criteria.abbreviation,
        required: false,
        type: 'checkbox'
      });
    });
  }

  return { fields: FieldDefinitions, label: 'Filter by ' + params.key };
});

const getCaseFilter = createAsync<
  AppProps.CategoryParams,
  any,
  CMxCommonApp.SubmitError
>(Case.CASE_STATUS, async (params, dispatch) => {
  const serviceLineConfig = appServiceLines.determineServiceLineConfig(
    params.serviceLine
  );
  let values = await api<any[]>({
    endpoint: `${
      serviceLineConfig.path
    }/casebuilder/filter/${params.categoryName.toString()}/v1`
  });

  // Temp fix - endpoint will be updated in CDMX-134
  values = [...values, commonEnums.uiStatus.CLOSE_NON_CHARGEABLE];

  let FieldDefinitions: CMxCommonApp.FieldDefinition[] = [];

  if (values !== undefined) {
    values.forEach(status => {
      const colors = getColor(status);

      if (colors) {
        FieldDefinitions.push({
          label: undefined,
          key: status,
          helpText: status,
          required: false,
          type: 'checkbox',
          color: colors.color,
          bgColor: colors.bgColor
        });
      } else {
        FieldDefinitions.push({
          label: undefined,
          key: status,
          helpText: status,
          required: false,
          type: 'checkbox'
        });
      }
    });
  }

  return { fields: FieldDefinitions, label: 'Filter by ' + params.key };
});

const getColor = (status: string) => {
  switch (status) {
    case uiStatus.IN_PROGRESS:
      return {
        color: statusColors.IN_PROGRESS,
        bgColor: statusBGColors.IN_PROGRESS
      };

    case uiStatus.AUTOMATED:
      return {
        color: statusColors.AUTOMATED,
        bgColor: statusBGColors.AUTOMATED
      };
    case uiStatus.MANUAL:
      return {
        color: statusColors.MANUAL,
        bgColor: statusBGColors.MANUAL
      };
    case uiStatus.CANCELLED:
      return {
        color: statusColors.CANCEL,
        bgColor: statusBGColors.CANCEL
      };
    case uiStatus.CLOSE_NON_CHARGEABLE:
      return {
        color: statusColors.CANCEL,
        bgColor: statusBGColors.CANCEL
      };
    case uiStatus.IN_PROCESS:
      return {
        color: statusColors.IN_PROGRESS,
        bgColor: statusBGColors.IN_PROGRESS
      };
    case uiStatus.UPDATED_MANUAL:
      return {
        color: statusColors.UPDATED_MANUAL,
        bgColor: statusBGColors.UPDATED_MANUAL
      };
  }

  return undefined;
};
const applyFilters = createAsync<
  CMxCommonApp.Filter[],
  CMxCommonApp.Filter[],
  CMxCommonApp.SubmitError
>(Case.FILTER, async (filters, dispatch) => {
  return filters;
});

const applyDateFilter = createAsync<
  CMxCommonApp.DateFilter,
  CMxCommonApp.DateFilter,
  CMxCommonApp.SubmitError
>(Case.DATE_FILTER, async (filters, dispatch) => {
  return filters;
});

const getShowOrderNumber = createAsync<
  {
    key: string;
    tenantId: string;
  },
  CMxAPI.ConfigurationValue,
  CMxCommonApp.SubmitError
>(Case.SHOW_ORDER_NUMBER, async (params, dispatch) => {
  const { key, tenantId } = params;

  const searchParams = new URLSearchParams({
    key: key,
    tenantId: tenantId
  });

  try {
    const showOrderNumber: CMxAPI.ConfigurationValue[] = await api<
      CMxAPI.ConfigurationValue[]
    >({
      endpoint: `/configuration/value/v1?${searchParams.toString()}`,
      init: {
        method: HttpMethods.GET
      }
    });
    return showOrderNumber[0];
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const getBillingGroup = createAsync<
  CMx.BillingGroupPayload,
  CMxAPI.PageableList<CMxAPI.Case>,
  CMxCommonApp.SubmitError
>(Case.BILLING_GROUP, async (params, dispatch) => {
  const { billingGroupId, serviceLine } = params;
  const signal = abortController.getSignal(Case.BILLING_GROUP);
  const serviceLineConfig = appServiceLines.determineServiceLineConfig(
    serviceLine
  );

  const searchParams = new URLSearchParams({
    page: '0',
    pageSize: '25',
    sort: 'dateOfService,DESC'
  });

  searchParams.append('sort', 'id,DESC');

  let body: any[] = [];

  body.push({
    key: `billing_group_id`,
    terms: [`${billingGroupId}`],
    type: 'EQ'
  });

  const billingGroupPageable = await api<CMxAPI.PageableList<CMxAPI.Case>>({
    endpoint: `${
      serviceLineConfig.path
    }/caseinstance/list/v2?${searchParams.toString()}`,
    init: {
      method: 'POST',
      signal
    },
    body: body
  });

  return billingGroupPageable;
});

const getSubmitterProcess = createAsync<
  CMx.SubmitterProcessPayload,
  CMxAPI.Case,
  CMxCommonApp.SubmitError
>(Case.SUBMITTER_ACCESSION_SEARCH, async (params, dispatch) => {
  const signal = abortController.getSignal(Case.SUBMITTER_ACCESSION_SEARCH);

  const serviceLineConfig = appServiceLines.determineServiceLineConfig({
    name: params.serviceLine
  });

  const searchParams = new URLSearchParams({
    page: '0',
    pageSize: '25',
    sort: 'dateOfService,DESC'
  });

  searchParams.append('sort', 'id,DESC');

  let body: any[] = [];

  body.push({
    key: `primary_clinical_identifier`,
    terms: [`${params.submitterAccession}`],
    type: 'EQ'
  });

  const submitterPageable = await api<CMxAPI.PageableList<CMxAPI.Case>>({
    endpoint: `${serviceLineConfig.path}/caseinstance/list/${
      serviceLineConfig.caseListVersion
    }?${searchParams.toString()}`,
    init: {
      method: 'POST',
      signal
    },
    body: body
  });

  const caseId = submitterPageable.content.length
    ? submitterPageable.content[0].id
    : false;
  if (caseId === false) {
    throw new Error(`no case found for ${params.submitterAccession}`);
  }

  const caseParams = new URLSearchParams(serviceLineConfig.caseDetailParms);

  const expanded: CMxAPI.Case = await api<CMxAPI.Case>({
    endpoint: `${serviceLineConfig.path}/caseinstance/${caseId}/details/${
      serviceLineConfig.caseDetailVersion
    }?${caseParams.toString()}`
  });

  const invoiceMode = getInvoiceMode(expanded);
  let descriptionRequests = formatDescriptionRequests(expanded);
  dispatch(
    fetchCodeRequests({
      caseDetail: expanded,
      descriptionRequests,
      invoiceMode
    })
  );

  return expanded;
});

const buildCodeRequests = (caseRun: CMxAPI.CaseRun) => {
  const artifacts = caseRun?.artifacts ?? [];
  return getCodeRequests(artifacts, caseRun);
};

const flattenCodeRequests = (codeRequests: CMxAPI.CodeRequest[][]) => {
  const flat = codeRequests.flat();
  objectUtils.uniqBy(flat, (code: any) => `${code.code}-${code.effectiveDate}`);
  return flat;
};

type CaseDetailAction = {
  action: string;
  caseRun: CMxAPI.CaseRunV2;
  caseOrganizationContext: CMxAPI.Organization;
};

const importToAmplify = createAsync<
  CaseDetailAction,
  CMxAPI.InvoicesResponse,
  CMxCommonApp.SubmitError
>(Case.IMPORT_TO_AMPLIFY, async (caseDetailAction, dispatch) => {
  const { caseRun, caseOrganizationContext } = caseDetailAction;
  const serviceLineConfig = appServiceLines.determineServiceLineConfig({
    name: caseRun.service_line
  });

  const endpoint = `/coding-api/processinstance/${serviceLineConfig.amplifyPath}/${caseRun.process_instance_guid}/v1?tenantIdOverride=${caseOrganizationContext.tenantId}`;

  try {
    const caseImport: CMxAPI.InvoicesResponse = await api<
      CMxAPI.InvoicesResponse
    >({
      endpoint,
      init: {
        method: 'POST'
      }
    });
    return caseImport;
  } catch (e) {
    dispatch(
      showFeedbackError({
        id: Date.now(),
        message: `Unable to import case into Amplify.`,
        dismissable: true
      })
    );
    throw e;
  }
});

type CaseCodeRequests = {
  caseDetail: CMxAPI.CaseV2 | CMxAPI.Case;
  descriptionRequests: CMxAPI.CodeRequest[];
  invoiceMode: boolean;
};

const fetchCodeRequests = createAsync<
  CaseCodeRequests,
  CMxAPI.CodeResponse,
  CMxCommonApp.SubmitError
>(
  Case.INVOICE_CODE_DESCRIPTIONS,
  async ({ caseDetail, descriptionRequests, invoiceMode }, dispatch) => {
    // even if there are no descriptionRequests, we still need to initialize
    // an organization context for a case.
    const primaryCase = getCaseRun(caseDetail);
    const tenantInfo: CMxAPI.Organization = await api<CMxAPI.Organization>({
      endpoint: `/organization/by-name/${primaryCase.tenant_name ??
        'Henry Ford Health System'}/v1`
    });
    dispatch(caseOrganizationContext(tenantInfo));

    if (descriptionRequests.length) {
      try {
        const descriptions = await api<CMxAPI.CodeResponse>({
          endpoint: `/dictionary/codes/description/v2?tenantId=${tenantInfo.parentTenantId}`,
          init: {
            method: 'POST'
          },
          body: descriptionRequests
        });
        const normalizeDescriptions = dictionaries.normalizeDescriptions(
          descriptions
        );
        if (invoiceMode) {
          dispatch(fetchInvoiceCodeDescriptions(normalizeDescriptions));
        }
        return normalizeDescriptions;
      } catch (e) {
        console.log(e);
      }
    }
    return Promise.resolve({});
  }
);

const navigateDetail = createAsync<CMxAPI.CaseRun, void, void>(
  Case.DETAIL_WILL_NAVIGATE,
  async (caseDetail, dispatch) => {
    dispatch(caseSelected(caseDetail));
    const queryString = window.location.search.slice(1);
    if (queryString) {
      dispatch(push(`${caseDetail.case_id}?${queryString}`));
      //@ts-ignore
    } else if (caseDetail.caserun_uid) {
      //@ts-ignore
      dispatch(push(`${caseDetail.caserun_uid}`));
    } else {
      dispatch(push(`${caseDetail.case_id}`));
    }
    return caseDetail;
  },
  { ...BROADCAST_ACTION_METADATA }
);

const getDetail = createAsync<
  CMx.CaseDetailPayload,
  CMxAPI.Case,
  CMxCommonApp.SubmitError
>(
  Case.DETAIL,
  async (payload, dispatch) => {
    const { caseId, serviceLine } = payload;

    const signal = abortController.getSignal(Case.DETAIL);

    const serviceLineConfig = appServiceLines.determineServiceLineConfig({
      name: serviceLine,
      caseIdentifier: caseId
    });

    dispatch(detailInitial(caseId));

    const caseParams = new URLSearchParams(serviceLineConfig.caseDetailParms);

    const expandedCaseDetail: CMxAPI.Case = await api<CMxAPI.Case>({
      endpoint: `${serviceLineConfig.path}/caseinstance/${caseId}/details/${
        serviceLineConfig.caseDetailVersion
      }?${caseParams.toString()}`,
      init: {
        signal
      }
    });

    // we will always start with the primary process.
    const primaryCase = getCaseRun(expandedCaseDetail);

    // if in bedside pro, get the rtf document's html
    if (appServiceLines.isIPC(primaryCase.service_line)) {
      await dispatch(getDocumentsAsHtml(primaryCase));
    }

    // fetch code descriptions
    const invoiceMode = getInvoiceMode(expandedCaseDetail);
    let descriptionRequests = formatDescriptionRequests(expandedCaseDetail);
    dispatch(
      fetchCodeRequests({
        caseDetail: expandedCaseDetail,
        descriptionRequests,
        invoiceMode
      })
    );

    // check for additional exams within a billing group
    if (isNumber(primaryCase.billing_group_id)) {
      dispatch(
        getBillingGroup({
          billingGroupId: primaryCase.billing_group_id,
          serviceLine: {
            name: serviceLine,
            caseIdentifier: caseId
          }
        })
      );
    }
    // if there's a submitter process fetch contents
    // https://codametrix.atlassian.net/browse/XAPP-1560
    if (
      primaryCase.submitter_process === false &&
      primaryCase.submitted_primary_clinical_identifier?.number
    ) {
      const accesionNumber =
        primaryCase.submitted_primary_clinical_identifier?.number;
      dispatch(
        getSubmitterProcess({
          submitterAccession: `${accesionNumber}`,
          serviceLine
        })
      );
    }

    dispatch(amplifyProcessInstance(primaryCase));

    return expandedCaseDetail;
  },
  { user: true }
);

export const getInvoiceMode = (expandedCaseDetail: CMxAPI.Case) => {
  if (cmxTypeguards.isCaseV2(expandedCaseDetail)) {
    return true;
  }
  const primaryCase = getCaseRun(expandedCaseDetail);
  return (primaryCase.invoices && primaryCase.invoices.length > 0) || false;
};

export const formatDescriptionRequests = (expandedCaseDetail: CMxAPI.Case) => {
  const invoiceMode = getInvoiceMode(expandedCaseDetail);
  const codeRequests = expandedCaseDetail.caseRuns.map(buildCodeRequests);
  let descriptionRequests = flattenCodeRequests(codeRequests);
  if (invoiceMode) {
    descriptionRequests = [
      ...descriptionRequests,
      ...cmxArtifacts.getInvoiceCodeRequests(expandedCaseDetail.caseRuns)
    ];
  }
  return descriptionRequests;
};

const amplifyProcessInstance = createAsync<
  CMxAPI.CaseRun,
  CMxAPI.InvoicesResponse,
  CMxCommonApp.CMxError
>(
  commonEnums.ProcessInstanceActions.DASHBOARD_INVOICE_AVAILABLE,
  async (caseRun, dispatch: any) => {
    const serviceLineConfig = appServiceLines.determineServiceLineConfig({
      name: caseRun.service_line
    });

    const processInstance = await api<CMxAPI.InvoicesResponse>({
      endpoint: `/coding-api/processinstance/${serviceLineConfig.amplifyPath}/${caseRun.process_instance_guid}/v1`
    });
    // For a miss, the response from the coding-api is empty but
    // 200.  We need to do our own verification on the response here.
    return processInstance;
  }
);

const createForm = (org: CMxAPI.Organization) => {
  if (org === undefined) {
    return;
  }

  const piece: CMxCommonApp.FieldDefinition = {
    label: org.organizationName,
    key: org.organizationCode,
    helpText: org.organizationName,
    required: false,
    level: org.parentLevel
  };

  var children = undefined;
  if (org.parentLevel !== 2) {
    children = org.children?.map(child => {
      return createForm(child);
    });
  }

  const sortedChildren = children?.sort(
    (
      first?: CMxCommonApp.FieldDefinition,
      second?: CMxCommonApp.FieldDefinition
    ) => {
      if (
        first &&
        second &&
        first.helpText !== undefined &&
        second.helpText !== undefined
      ) {
        var firstText = first.helpText.toUpperCase();
        var secondText = second.helpText.toUpperCase();
        return firstText < secondText ? -1 : firstText > secondText ? 1 : 0;
      }
      return 0;
    }
  );

  if (sortedChildren !== undefined && sortedChildren.length > 0) {
    piece.children = sortedChildren as CMxCommonApp.FieldDefinition[];
  }

  return piece;
};

const getTenantFilter = createAsync<
  number,
  CMxCommonApp.FieldDefinition[],
  CMxCommonApp.SubmitError
>(Case.TENANT, async (id: number) => {
  const endpoint = isNaN(id as number)
    ? `/organization/tenant/v1?tenantId=${id}`
    : `/organization/id/v1?organizationId=${id}`;
  const orgOpts = {
    endpoint: endpoint
  };
  const organization = await api<CMxAPI.Organization>(orgOpts);

  if (organization.parentLevel === 0) {
    let form = createForm(organization);

    const children = form?.children;
    if (children !== undefined) {
      return children;
    }
  }

  let form = createForm(organization);

  if (form === undefined) {
    form = {
      key: 'invalid'
    };
  }
  return [form];
});

/**
 * Asynchronously launches a work item in Amplify based on the provided configuration.
 *
 * @param {CMxCommonApp.LaunchWorkItemConfig} launchConfig - The configuration object for launching the work item,
 * containing details such as the service line, case ID, process ID, and flags indicating the type of work item.
 * Launch configs come from the Service-Desk which is a different repo than ui-omnibus
 */
const launchWorkitemInAmplify = createAsync<
  CMxCommonApp.LaunchWorkItemConfig,
  void,
  CMxCommonApp.CMxError
>(
  Case.LAUNCH_WORKITEM_IN_AMPLIFY,
  async (launchConfig: CMxCommonApp.LaunchWorkItemConfig, dispatch) => {
    let route = 'worklist';
    if (launchConfig.isCodingQuality) {
      route = 'coding-quality';
    } else if (launchConfig.isQualityAssessment) {
      route = 'quality-assessment';
    }

    dispatch(
      push(
        `/cmx/${route}/${launchConfig.serviceLine}/case/${
          launchConfig.caseId
        }/case-run/${launchConfig.processId}${
          launchConfig.hasAmplifyCaseRunV2Access ? '/v2' : ''
        }/`,
        {
          worklistContext: launchConfig.worklistContext,
          servicedeskMode: launchConfig.servicedeskMode,
          workitemId: launchConfig.workitemId,
          sessionId: launchConfig.sessionId,
          qaCollectionUuid: launchConfig.qaCollectionUuid,
          launchConfigServiceLine: launchConfig.serviceLine
        }
      )
    );
  }
);

const getPrevOrNextCase = createAsync<
  CMxCommonApp.CursorAPIPayload,
  any,
  CMxCommonApp.CMxError
>(
  Case.GET_PREV_OR_NEXT_CASE,
  async (cursorAPIPayload: CMxCommonApp.CursorAPIPayload, dispatch) => {
    try {
      const {
        cursorAPIContext,
        operation,
        hasAmplifyCaseRunV2Access
      } = cursorAPIPayload;
      const xcaliberClient = getServiceDeskClient();

      xcaliberClient.initApolloClient();

      const getPrevOrNextCase = gql`
        mutation GetPrevOrNextCase($cursorPayload: CursorPayload!) {
          GetPrevOrNextCase(cursorPayload: $cursorPayload) {
            case_id
            cmx_process_id
            cmx_case_uid
            service
            session_id
            key
            status
            status_msg
          }
        }
      `;
      const response = await xcaliberClient.apolloClient.mutate({
        mutation: getPrevOrNextCase,
        variables: {
          cursorPayload: {
            case_id: cursorAPIContext.workitemId,
            query: {
              filters: cursorAPIContext.worklistContext.query.filters,
              pagination: cursorAPIContext.worklistContext.query.pagination,
              groupByEncounter:
                cursorAPIContext.worklistContext.query.groupByEncounter
            },
            operation: operation,
            session_id: cursorAPIContext.sessionId
              ? cursorAPIContext.sessionId
              : null
          }
        }
      });

      const result = response?.data?.GetPrevOrNextCase;
      let feedbackFlag = false;
      if (result.status === commonEnums.ResponseStatus.SUCCESS) {
        if (result?.case_id) {
          const launchConfig: CMxCommonApp.LaunchWorkItemConfig = {
            serviceLine: result?.service,
            caseId: result?.cmx_case_uid,
            processId: result?.cmx_process_id,
            workitemId: result?.case_id,
            servicedeskMode: cursorAPIContext.servicedeskMode,
            worklistContext: cursorAPIContext.worklistContext,
            sessionId: result?.session_id,
            isCodingQuality: cursorAPIContext.worklistContext?.isCodingQuality,
            isQualityAssessment:
              cursorAPIContext.worklistContext?.isQualityAssessment,
            hasAmplifyCaseRunV2Access: hasAmplifyCaseRunV2Access
          };
          dispatch(launchWorkitemInAmplify(launchConfig));
        } else {
          if (result?.case_id === null) {
            let path = '/cmx/worklist/';
            if (cursorAPIContext.worklistContext?.isCodingQuality) {
              path = '/cmx/coding-quality/';
            } else if (cursorAPIContext.worklistContext?.isQualityAssessment) {
              path = '/cmx/quality-assessment/';
            }
            dispatch(push(path));
          } else {
            feedbackFlag = true;
          }
        }
      } else {
        feedbackFlag = true;
      }
      if (feedbackFlag) {
        dispatch(
          amplifyFeedback({
            message: 'There was an issue fetching the next case.',
            meta: commonEnums.FeedbackMeta.ERROR,
            dismissable: true
          })
        );
      }
    } catch (error) {
      dispatch(
        amplifyFeedback({
          message: 'There was an issue fetching the next case.',
          meta: commonEnums.FeedbackMeta.ERROR,
          dismissable: true
        })
      );
    }
  }
);

const getDocumentsAsHtml = createAsync<
  CMxAPI.CaseRun,
  CMxCommonApp.DecoratedNote[],
  CMxCommonApp.SubmitError
>('GET_HTML', async (caseRun, dispatch) => {
  const { tenant_name, child_case_list } = caseRun;
  const tenantInfo = await api<CMxAPI.Organization>({
    endpoint: `/organization/by-name/${tenant_name}/v1`
  });

  let docs = await getDocuments(caseRun, tenantInfo);

  let htmlNotes: CMxCommonApp.DecoratedNote[] = [];
  let caseRuns: CMxAPI.CaseRun[] = [caseRun];

  for (const child of child_case_list ?? []) {
    if (child.case_uid !== caseRun.case_uid) {
      const signal = abortController.getSignal(commonActions.Case.DETAIL);

      const serviceLineConfig = appServiceLines.determineServiceLineConfig({
        name: caseRun.service_line
      });
      const caseParams = new URLSearchParams(serviceLineConfig.caseDetailParms);

      const expandedCaseDetail: CMxAPI.Case = await api<CMxAPI.Case>({
        endpoint: `${serviceLineConfig.path}/caseinstance/${
          child.case_uid
        }/details/${
          serviceLineConfig.caseDetailVersion
        }?${caseParams.toString()}`,
        init: {
          signal
        }
      });

      caseRuns = caseRuns.concat(expandedCaseDetail?.caseRuns ?? []);
    }
  }

  let newDocs = [];
  const allowedDocs = new Set(['application/pdf', 'text/html', 'text/plain']);
  for (let doc of docs) {
    const { id, mimeType } = doc;

    if (!id) {
      console.warn(`no id for document`, doc);
      continue;
    }

    if (allowedDocs.has(mimeType)) {
      // caseruns are an amalgam of all case runs associated with the case
      // so we need to find the caseRun that should be looked up in order
      // with this document
      caseRuns.sort((a, b) => {
        // if either of the docs a parent, but not both, thenn the one with the parent should be first
        if (!a.parent_case || !b.parent_case) {
          if (a.parent_case) {
            return 1;
          }
          if (b.parent_case) {
            return -1;
          }
        }
        if (
          a.case_created_datetime === null ||
          b.case_created_datetime === null
        )
          return 0;
        // fallback to the lastest created date
        return a.case_created_datetime > b.case_created_datetime ? 1 : -1;
      });

      const caseRun = caseRuns?.find(caseRun => {
        return caseRun.notes?.find(note => {
          //@ts-ignore
          if (note.unique_document_number === doc.uniqueDocumentNumber)
            return true;
          return caseRun.caserun_uid === doc.businessKey;
        });
      });
      const notes = caseRun?.notes as CMxAPI.Note[];
      const caseRunNote = notes?.find(note => {
        return note.unique_document_number === doc.uniqueDocumentNumber;
      });

      try {
        const endpoint = `/documents/download/${id}${
          mimeType === 'application/pdf' ? '/encoded' : ''
        }/v1`;
        const content = await commonApi.request(endpoint);
        const text = await content.text();
        const htmlNote = documentUtils.decoratedNoteFactory({
          description: caseRunNote?.description ?? doc.documentDescription,
          content: text,
          filename: caseRunNote?.note_specialty_designation ?? 'Document',
          type: doc.documentType,
          document_id: doc.documentId,
          id,
          filetype: mimeType
        });

        htmlNotes.push(htmlNote);
        newDocs.push({
          ...doc,
          documentName: caseRunNote?.note_specialty_designation ?? 'Document'
        });
      } catch (e) {
        console.log('Cases: error fetching document', doc, e);
      }
    }
  }

  docs = newDocs.filter(doc => doc.documentId !== null);

  dispatch(docMetadataAvailable(docs));
  return htmlNotes;
});

export {
  importToAmplify,
  caseOrganizationContext,
  amplifyProcessInstance,
  list,
  getBillingGroup,
  navigateDetail,
  getDetail,
  getDictionary,
  getSubmitterProcess,
  applyFilters,
  applyDateFilter,
  fetchCodeRequests,
  fetchInvoiceCodeDescriptions,
  featureSelection,
  featureActivation,
  getCaseFilter,
  getTenantFilter,
  detailToggleArtifacts,
  clearAllFilters,
  applyDefaultFilters,
  showProcessingTimeline,
  setActiveProcess,
  launchWorkitemInAmplify,
  getPrevOrNextCase,
  getDocumentsAsHtml,
  setActiveNote,
  getShowOrderNumber
};
