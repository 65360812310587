import theme from '../../theme';

export const styles = () => {
  const mainGrid = {
    background: theme.colors.text['highEmphasis'],
    color: theme.colors.text['light'],
    stroke: theme.colors['stroke']['100'],
    ...theme.textStyles['Body 1'],
    borderColor: theme.colors['stroke']['200'],
    borderRadius: theme.radii[3],
    transform: `translateX(-8px) !important`,
    width: 'max-content',
    '.chakra-popover__body': {
      pr: 0
    }
  };
  const gridItem = {
    background: theme.colors.text['highEmphasis'],
    color: theme.colors.text['light'],
    ...theme.textStyles['Body 2']
  };

  const bottomDivider = {
    borderTop: `${theme.borders['1px']} ${theme.colors.stroke['300']}`
  };

  const orgChevronBox = {
    color: theme.colors.foundation['300'],
    borderRadius: theme.radii[3],
    background: theme.colors.opacity['secondary'],
    w: theme.space[16],
    h: theme.space[16],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  const switchItem = {
    alignItems: 'center',
    pt: theme.space[8],
    pb: theme.space[8],
    pl: theme.space[8],
    pr: theme.space[8],
    w: theme.space[240],
    h: theme.space[32]
  };

  const switchArrow = {
    marginLeft: 'auto'
  };

  const iconBox = {
    backgroundColor: theme.colors.opacity['secondary'],
    borderRadius: '4px',
    marginTop: '4px',
    marginBottom: '4px'
  };

  const footerButton = {
    ml: theme.space[12],
    w: theme.space[80],
    h: theme.space[32],
    '.chakra-popover__close-btn': {
      position: `inherit !important`,
      right: 'auto'
    }
  };

  const popoverHeader = {
    '.chakra-popover__header': {
      borderBottomWidth: 0
    }
  };

  const popoverFooter = {
    h: theme.space[52],
    width: '100%',
    borderTopColor: theme.colors['stroke']['300'],
    pt: theme.space[12],
    pb: theme.space[12],
    p2: theme.space[12]
  };

  const bodyGrid = {
    pb: theme.space[16],
    pl: theme.space[8],
    pr: theme.space[8]
  };

  const headerGrid = {
    borderBottomWidth: 0,
    pt: theme.space[12],
    pl: theme.space[12]
  };

  const popoverOrgBox = {
    w: theme.space[240],
    borderRight: `${theme.borders['1px']} ${theme.colors.stroke['300']}`,
    pr: theme.space[8]
  };
  const popoverServicelineBox = {
    w: theme.space[240]
  };
  const popoverInsightsBox = {
    w: theme.space[240],
    pt: theme.space[16],
    pb: theme.space[2]
  };
  const subnavDivider = {
    mt: theme.space[16],
    mb: theme.space[8],
    mr: theme.space[8]
  };
  const subnavContainer = {
    direction: 'row',
    alignItems: 'center',
    ml: 2
  };

  return {
    '.mainGrid': mainGrid,
    '.gridItem': gridItem,
    '.bottomDivider': bottomDivider,
    '.orgChevronBox': orgChevronBox,
    '.switchItem': switchItem,
    '.switchArrow': switchArrow,
    '.iconBox': iconBox,
    '.footerButton': footerButton,
    '.popoverHeader': popoverHeader,
    '.popoverFooter': popoverFooter,
    '.bodyGrid': bodyGrid,
    '.headerGrid': headerGrid,
    '.popoverOrgBox': popoverOrgBox,
    '.popoverServicelineBox': popoverServicelineBox,
    '.popoverInsightsBox': popoverInsightsBox,
    '.subnavDivider': subnavDivider,
    '.subnavContainer': subnavContainer
  };
};
