/// <reference path="../types.d.ts" />

import InitialState from './initial-state';
import * as dictionaryValues from '../actions/dictionary-values';
import * as dictionary from '../actions/dictionary';
import * as dictionaryRequest from '../actions/dictionary-request.actions';

import { LOAD_STATES } from './common';

import { jumpContext } from '../actions/contexts';
import { commonEnums } from '@codametrix/ui-common';
import { cmxReducerFactory } from './reducer-utils';
import {
  columnMetadataDataItem,
  defaultDictionary
} from '../tests/fixtures/stubs/dictionary';
import {
  createDataGridRows,
  dictionaryMetadataFieldGroup
} from './state/dictionary-values';
import { syncActions } from '../actions/dictionary-values';
import { formErrors } from '../stubs/form-errors';
const { DictionaryStatus, FilterTypes } = commonEnums;

const createColumns = (
  columns: CMxAPI.ColumnDefinition[],
  statusFilter: string
) => {
  let dataGridCols = [];
  dataGridCols.push(
    ...columns
      .sort((a, b) => a.columnIndex - b.columnIndex)
      .map(column => {
        return {
          key: column.name,
          displayName: column.name,
          headerComponentParams: column.keyColumnIndicator
            ? { menuIcon: 'fa-key', displayName: column.name }
            : undefined
        };
      })
  );

  if (statusFilter === DictionaryStatus.PUBLISHED) {
    dataGridCols.push({
      key: 'remove',
      action: 'remove',
      displayName: 'Archive',
      isSortable: false,
      defaultSort: 'NONE'
    });
  }

  return dataGridCols;
};

const createSearchForms = (columns: CMxAPI.ColumnDefinition[]) => {
  return columns.map(column => {
    return {
      label: column.name,
      key: column.name,
      type: FilterTypes.LIKE
    };
  });
};

const createRowEditForm = (cols: CMxAPI.ColumnDefinition[]) => {
  const valueFields = cols.map(col => {
    return {
      label: col.name,
      key: col.name,
      required: true,
      helpText: '',
      type: 'text',
      readonly: false
    };
  });

  const cellFieldGroup: CMxCommonApp.FieldGroup = {
    label: 'Cells',
    fields: valueFields
  };

  const rowEditFormButtons = [
    { buttonType: 'submit', buttonText: 'Save' },
    { buttonType: 'link', buttonText: 'Back' }
  ];

  return {
    fieldGroups: [dictionaryMetadataFieldGroup, cellFieldGroup],
    buttons: rowEditFormButtons
  };
};

const createRowAddForm = (cols: CMxAPI.ColumnDefinition[]) => {
  const valueFields = cols.map(col => {
    return {
      label: col.name,
      key: col.name,
      required: true,
      helpText: '',
      type: 'text',
      readonly: false
    };
  });

  const cellFieldGroup: CMxCommonApp.FieldGroup = {
    label: 'This row will be added to the end of the dictionary.',
    fields: valueFields
  };

  const rowAddFormButtons = [
    { buttonType: 'submit', buttonText: 'Save' },
    { buttonType: 'link', buttonText: 'Back' }
  ];

  return {
    fieldGroups: [cellFieldGroup],
    buttons: rowAddFormButtons
  };
};

const reducer = cmxReducerFactory(InitialState.dictionaryValues)
  .case(dictionaryValues.listItems.async.started, state => {
    return {
      ...state,
      items: [],
      loadState: LOAD_STATES.initial,
      formErrors: formErrors.noErrors
    };
  })
  .case(dictionaryValues.listItems.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(dictionaryValues.listItems.async.done, (state, { result }) => {
    const { sortablePageable } = state;
    const dataGridRows = createDataGridRows(result.content);

    const sp = {
      ...sortablePageable,
      pageableDefinition: {
        ...result,
        items: dataGridRows
      }
    };

    return {
      ...state,
      sortablePageable: sp,
      items: dataGridRows,
      pageable: result,
      loadState: LOAD_STATES.done
    };
  })
  .case(dictionary.listItems.async.started, state => {
    // wipe the slate clean when the *dictionaries list* is called.
    return {
      ...state,
      loadState: LOAD_STATES.initial,
      formErrors: formErrors.noErrors
    };
  })
  .case(dictionary.loadDictionary.async.started, state => {
    const sortablePageable =
      state.sortablePageable ?? InitialState.dictionary.sortablePageable;
    return {
      ...state,
      sortablePageable,
      items: [],
      loadState: LOAD_STATES.initial,
      criteriaLoadState: LOAD_STATES.initial,
      formErrors: formErrors.noErrors,
      isDeleteModalOpen: false,
      updatedRows: InitialState.dictionaryValues.updatedRows
    };
  })
  .case(
    dictionaryValues.loadDictionary.async.done,
    (state, { params, result: dictionary }) => {
      const statusFilter =
        params.sortablePageable.filterableOptions.filters?.find(
          filter => filter.key === 'status'
        )?.terms[0] || '';
      const columns = createColumns(dictionary.columnDefinitions, statusFilter);
      const searchForms = createSearchForms(dictionary.columnDefinitions);
      const rowEditForm = createRowEditForm(dictionary.columnDefinitions);
      const rowAddForm = createRowAddForm(dictionary.columnDefinitions);

      const criteriaFilter = {
        ...state.criteriaFilter,
        filters: params.sortablePageable?.filterableOptions.filters || [],
        dateFilter: undefined,
        searchForms
      };

      return {
        ...state,
        criteriaFilter,
        columnDefs: columns,
        selectedDictionary: {
          ...dictionary,
          crossMapIndicator: dictionary.crossMapIndicator ?? false
        },
        rowEditForm,
        rowAddForm,
        criteriaLoadState: LOAD_STATES.done,
        showDraft: false
      };
    }
  )

  .case(dictionaryValues.saveDictionaryRow.async.started, state => {
    return { ...state, loadState: LOAD_STATES.initial };
  })
  .case(dictionaryValues.saveDictionaryRow.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })

  .case(dictionaryValues.saveDictionaryRow.async.done, (state, { result }) => {
    return {
      ...state,
      activeItem: result,
      loadState: LOAD_STATES.done,
      showDraft: true
    };
  })

  .case(dictionaryValues.loadValue.async.started, (state, params) => {
    return {
      ...state,
      loadState: LOAD_STATES.initial,
      selectedRowIndex: params.data
    };
  })
  .case(dictionaryValues.loadValue.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })

  .case(dictionaryValues.loadValue.async.done, (state, { result }) => {
    return { ...state, selectedRowIndex: result, loadState: LOAD_STATES.done };
  })

  .case(dictionary.listItems.async.started, (state, res) => {
    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: [
          { key: 'status', terms: [DictionaryStatus.PUBLISHED], type: 'IN' }
        ]
      },
      sortablePageable: {
        ...state.sortablePageable,
        pageableDefinition: {
          ...state.sortablePageable.pageableDefinition,
          pageable: {
            sort: {
              sorted: false,
              unsorted: true,
              empty: true
            },
            offset: 0,
            pageSize: 25,
            pageNumber: 0,
            unpaged: false,
            paged: true
          },
          size: 25
        },
        filterableOptions: {
          filters: [
            { key: 'status', terms: [DictionaryStatus.PUBLISHED], type: 'IN' }
          ]
        }
      },
      selectedRowIndex: -1,
      selectedDictionary: defaultDictionary,
      formErrors: formErrors.noErrors
    };
  })
  .case(dictionaryRequest.uploadDictionary.async.started, (state, params) => {
    const statusFilter = !state.isCorrection
      ? [
          {
            key: 'status',
            terms: ['draft'],
            type: 'IN'
          }
        ]
      : state.criteriaFilter.filters;
    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: statusFilter
      },
      sortablePageable: {
        ...state.sortablePageable,
        filterableOptions: {
          filters: statusFilter
        },

        pageableDefinition: {
          ...state.sortablePageable.pageableDefinition,
          pageable: {
            sort: {
              sorted: false,
              unsorted: true,
              empty: true
            },
            offset: 0,
            pageSize: 25,
            pageNumber: 0,
            unpaged: false,
            paged: true
          },
          size: 25
        }
      },
      formErrors: formErrors.noErrors
    };
  })
  .case(dictionaryRequest.uploadDictionary.async.failed, (state, e) => {
    const formErrors: CMxCommonApp.FormErrors = {
      ...e.error.errors,
      errors: [
        e.error?.errors?.message
          ? 'Error: ' + e.error.errors.message
          : 'Error: ' + e.error.message
      ]
    };
    return {
      ...state,
      loadState: LOAD_STATES.failed,
      formErrors
    };
  })
  .case(dictionaryRequest.uploadDictionary.async.done, state => {
    return {
      ...state,
      loadState: LOAD_STATES.done,
      selectedRowIndex: -1
    };
  })
  .case(dictionaryValues.publishDictionary.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.initial,
      criteriaLoadState: LOAD_STATES.initial
    };
  })
  .case(dictionaryValues.publishDictionary.async.failed, state => {
    return {
      ...state,
      loadState: LOAD_STATES.done,
      criteriaLoadState: LOAD_STATES.done
    };
  })
  .case(dictionaryValues.publishDictionary.async.done, (state, { result }) => {
    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: [
          { key: 'status', terms: [DictionaryStatus.PUBLISHED], type: 'IN' }
        ]
      },
      sortablePageable: {
        ...state.sortablePageable,
        pageableDefinition: {
          ...state.sortablePageable.pageableDefinition,
          pageable: {
            sort: {
              sorted: false,
              unsorted: true,
              empty: true
            },
            offset: 0,
            pageSize: 25,
            pageNumber: 0,
            unpaged: false,
            paged: true
          },
          size: 25
        },
        filterableOptions: {
          filters: [
            { key: 'status', terms: [DictionaryStatus.PUBLISHED], type: 'IN' }
          ]
        }
      },
      loadState: LOAD_STATES.done,
      criteriaLoadState: LOAD_STATES.done
    };
  })
  .case(syncActions.setStatusFilter, (state, status) => {
    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: [{ key: 'status', terms: [status], type: 'IN' }]
      },
      sortablePageable: {
        ...state.sortablePageable,
        filterableOptions: {
          filters: [{ key: 'status', terms: [status], type: 'IN' }]
        }
      },
      loadState: LOAD_STATES.initial,
      criteriaLoadState: LOAD_STATES.done
    };
  })
  .case(
    dictionaryValues.initializeColumnMetadataEditor.async.started,
    state => {
      const { columnMetadataEditor, selectedDictionary } = state;
      return {
        ...state,
        columnMetadataEditor: {
          ...columnMetadataEditor,
          loadState: LOAD_STATES.initial,
          containsDictionaryMappings: selectedDictionary.crossMapIndicator,
          columnMetadata: selectedDictionary.columnDefinitions
        }
      };
    }
  )
  .case(dictionaryValues.initializeColumnMetadataEditor.async.failed, state => {
    const { columnMetadataEditor } = state;
    return {
      ...state,
      columnMetadataEditor: {
        ...columnMetadataEditor,
        loadState: LOAD_STATES.failed
      }
    };
  })
  .case(
    dictionaryValues.initializeColumnMetadataEditor.async.done,
    (state, { result }) => {
      const { columnMetadataEditor } = state;
      let columnMetadata = columnMetadataEditor.columnMetadata;
      // if columnMetadata has no already been set, set it to result
      if (!columnMetadata?.length) {
        columnMetadata = result;
      }
      return {
        ...state,
        columnMetadataEditor: {
          ...columnMetadataEditor,
          columnMetadata: columnMetadata,
          loadState: LOAD_STATES.done
        }
      };
    }
  )
  .case(dictionaryValues.listDictionaryHistory.async.started, state => {
    return {
      ...state,
      historyLoadState: LOAD_STATES.initial,
      listDictionaryHistory: []
    };
  })
  .case(dictionaryValues.listDictionaryHistory.async.failed, state => {
    return {
      ...state,
      historyLoadState: LOAD_STATES.failed,
      listDictionaryHistory: []
    };
  })
  .case(dictionaryValues.listDictionaryHistory.async.done, (state, result) => {
    return {
      ...state,
      historyLoadState: LOAD_STATES.done,
      listDictionaryHistory: result.result.map(res => {
        const {
          id,
          dictionaryCsvS3Uri,
          requestStatus,
          requestType,
          updatedByFullName,
          updatedDate
        } = res;
        return {
          id,
          dictionaryCsvS3Uri,
          requestStatus,
          requestType,
          updatedByFullName,
          updatedDate
        };
      }) as CMxAPI.DictionaryHistory[]
    };
  })
  .case(
    dictionaryValues.listDictionaryOptions.async.done,
    (state, res: any) => {
      return {
        ...state,
        selectedTenantName: res.result.organizationName
      };
    }
  )
  .case(syncActions.selectKeyColumn, (state, result) => {
    const { columnMetadataEditor } = state;

    const columnMetadata = columnMetadataEditor.columnMetadata.map(col => {
      return { ...col, keyColumnIndicator: col.columnIndex === result };
    });
    return {
      ...state,
      columnMetadataEditor: {
        ...columnMetadataEditor,
        columnMetadata
      }
    };
  })
  .case(syncActions.selectActiveColumn, (state, result) => {
    const { columnMetadataEditor } = state;

    const referenceColumn =
      result.cellReferences[0]?.lookupProperty || undefined;
    const referenceDictionary =
      result.cellReferences[0]?.dictionaryName || undefined;
    const isRefValueList = result.cellReferences[0]?.isRefValueList || false;
    const referenceTenant =
      result.cellReferences[0]?.organizationName || undefined;
    const textEditable = result.cellReferences[0]?.textEditable || false;

    return {
      ...state,
      columnMetadataEditor: {
        ...columnMetadataEditor,
        activeColumn: result,
        activeColumnMetadataObj: {
          ...columnMetadataDataItem,
          columnName: result.name,
          referenceColumn,
          referenceDictionary,
          referenceTenant,
          isRefValueList,
          textEditable
        }
      }
    };
  })
  .case(dictionaryValues.listColumnOptions.async.started, (state, res) => {
    const { columnMetadataEditor } = state;
    columnMetadataEditor.activeColumnMetadataObj.referenceDictionary =
      res.dictionaryName;
    return {
      ...state,
      columnMetadataEditor: {
        ...columnMetadataEditor
      }
    };
  })

  .case(dictionaryValues.chooseColumnReference, (state, res) => {
    const { columnMetadataEditor } = state;
    columnMetadataEditor.activeColumnMetadataObj.referenceColumn = res.name;
    return {
      ...state,
      columnMetadataEditor: {
        ...columnMetadataEditor
      }
    };
  })
  .case(syncActions.saveColumnMapping, (state, res) => {
    const { columnMetadataEditor, selectedDictionary } = state;
    const {
      referenceTenant,
      referenceColumn,
      referenceDictionary,
      isRefValueList,
      textEditable
    } = res.mappingInfo;

    let organizationName = referenceTenant;
    if (
      organizationName !== state.selectedTenantName &&
      state.selectedTenantName !== ''
    ) {
      organizationName = state.selectedTenantName;
    }

    let parentOrganizationName = null;
    if (
      ['CPT Codes', 'Modifiers'].includes(referenceDictionary!) &&
      organizationName !== 'CodaMetrix'
    ) {
      parentOrganizationName = 'Codametrix';
    }

    const cellReference: CMxAPI.CellReference = {
      parentOrganizationName: parentOrganizationName || null,
      organizationName: organizationName || '',
      dictionaryName: referenceDictionary || '',
      lookupProperty: referenceColumn || '',
      id: -1,
      columnDefinitionId: res.columnInfo.id,
      isRefValueList: isRefValueList,
      textEditable: textEditable
    };

    const columnMetadata = columnMetadataEditor.columnMetadata.map(col => {
      if (col.id === res.columnInfo.id) {
        return {
          ...col,
          cellReferences: [cellReference]
        };
      }
      return col;
    });

    let updatedRows =
      state.updatedRows ?? state.sortablePageable.pageableDefinition.content;

    updatedRows = updatedRows.map(row => {
      const cells = row.cells.map(cell => {
        return cell.columnDefinitionId === res.columnInfo.id
          ? {
              ...cell,
              columnDefinition: {
                ...cell.columnDefinition,
                cellReferences: [cellReference]
              }
            }
          : cell;
      });
      return { ...row, cells };
    });

    return {
      ...state,
      columnMetadataEditor: {
        ...columnMetadataEditor,
        columnMetadata
      },
      selectedDictionary: {
        ...selectedDictionary,
        columnDefinitions: columnMetadata
      },
      updatedRows
    };
  })
  .case(syncActions.removeColumnMapping, (state, res) => {
    const { columnMetadataEditor } = state;

    const columnMetadata = columnMetadataEditor.columnMetadata.map(col => {
      if (col.id === res.id) {
        return {
          ...col,
          cellReferences: []
        };
      }
      return col;
    });

    let updatedRows =
      state.updatedRows ?? state.sortablePageable.pageableDefinition.content;

    updatedRows = updatedRows?.map(row => {
      const cells = row.cells.map(cell => {
        return cell.columnDefinitionId === res.id
          ? {
              ...cell,
              columnDefinition: {
                ...cell.columnDefinition,
                cellReferences: []
              }
            }
          : cell;
      });
      return { ...row, cells };
    });

    return {
      ...state,
      columnMetadataEditor: {
        ...columnMetadataEditor,
        columnMetadata
      },
      updatedRows
    };
  })
  .case(
    dictionaryValues.saveColumnDefinitions.async.failed,
    (state, response) => {
      const { columnMetadataEditor } = state;

      return {
        ...state,
        colErrors: response.error.errors,
        columnMetadataEditor: {
          ...columnMetadataEditor,
          loadState: LOAD_STATES.failed
        }
      };
    }
  )
  .case(
    dictionaryValues.saveColumnDefinitions.async.done,
    (state, { result }) => {
      return {
        ...state,
        selectedDictionary: result,
        updatedRows: InitialState.dictionaryValues.updatedRows
      };
    }
  )
  .case(dictionary.updatePublishedMetadata.async.done, (state, { result }) => {
    return {
      ...state,
      updatedRows: InitialState.dictionaryValues.updatedRows
    };
  })
  .case(syncActions.toggleArchiveModal, (state, isOpen) => {
    return {
      ...state,
      archiveModal: {
        ...state.archiveModal,
        isOpen,
        archiveResult: []
      }
    };
  })
  .case(dictionaryValues.selectArchiveRow.async.started, state => {
    return {
      ...state,
      archiveModal: {
        ...state.archiveModal,
        loadState: LOAD_STATES.initial
      }
    };
  })
  .case(dictionaryValues.selectArchiveRow.async.done, (state, payload) => {
    return {
      ...state,
      archiveModal: {
        ...state.archiveModal,
        selectedRow: payload.result,
        loadState: LOAD_STATES.done
      }
    };
  })
  .case(syncActions.savePotentialArchives, (state, payload) => {
    return {
      ...state,
      archiveModal: {
        ...state.archiveModal,
        potentialArchives: payload
      }
    };
  })
  .case(dictionaryValues.archiveRow.async.started, (state, payload) => {
    return {
      ...state,
      archiveModal: {
        ...state.archiveModal,
        loadState: LOAD_STATES.started
      }
    };
  })
  .case(dictionaryValues.archiveRow.async.done, (state, payload) => {
    return {
      ...state,
      archiveModal: {
        ...state.archiveModal,
        archiveResult: payload.result,
        loadState: LOAD_STATES.done
      }
    };
  })
  .case(dictionaryValues.toggleDeleteModal, (state, showWarning) => {
    return {
      ...state,
      isDeleteModalOpen: showWarning
    };
  })
  .case(dictionaryValues.toggleUploadType, (state, isCorrection) => {
    return {
      ...state,
      isCorrection
    };
  })
  .case(dictionaryValues.toggleInfoModal, (state, payload) => {
    return {
      ...state,
      isInfoModalOpen: payload
    };
  })
  .case(dictionaryValues.toggleSecurityModal, (state, payload) => {
    return {
      ...state,
      isSecurityModalOpen: payload
    };
  })
  .case(dictionaryValues.togglePublishModal, (state, payload) => {
    return {
      ...state,
      isPublishModalOpen: payload
    };
  })
  .case(
    dictionaryValues.updateDictionaryDescription.async.done,
    (state, { result }) => {
      return {
        ...state,
        selectedDictionary: result
      };
    }
  )
  .case(syncActions.updateDictionaryValue, (state, payload) => {
    return {
      ...state,
      columnDefs: payload?.colDefs ?? state.columnDefs,
      selectedDictionary:
        payload?.selectedDictionary ?? state.selectedDictionary,
      selectedRowIndex: payload?.selectedRowIndex ?? state.selectedRowIndex,
      items: payload.items ?? state.items
    };
  })
  .cases([jumpContext.async.started], state => {
    return { ...InitialState.dictionaryValues };
  })
  .case(dictionaryValues.listColumnValues.async.done, (state, { result }) => {
    state.mappedColValues.push({ key: result[0].columnName, values: result });
    return {
      ...state
    };
  });

export default reducer;
