import React from 'react';
import { styles } from '../org-serviceline/org-servicelines.styles';
import { Box, Flex, Text } from '@chakra-ui/react';

type DashBoardSelectSubNavProps = {
  activeDashboardOptions: {
    name: string;
    dashboards: { dashboardName: string; dashboardResource: string }[];
  };
  activeDashboardName: string;
  handleDashboardSelection: (boardName: string, dashboardPath: string) => void;
};

export const DashboardSelectionSubNav: React.FC<DashBoardSelectSubNavProps> = ({
  activeDashboardOptions,
  activeDashboardName,
  handleDashboardSelection,
  ...props
}) => {
  const classes = styles();

  return (
    <Box sx={classes['.popoverInsightsBox']}>
      <Flex
        sx={classes['.popoverHeader']}
        key={activeDashboardOptions.name + 'header'}
        flexDirection="column">
        {activeDashboardOptions.dashboards.map(dashboard => {
          const { dashboardName, dashboardResource } = dashboard;
          return (
            <Flex
              sx={classes['.switchItem']}
              key={dashboardName}
              onClick={() => {
                handleDashboardSelection(dashboardName, dashboardResource);
              }}
              className={`hoverCursor ${
                activeDashboardName ===
                dashboardName.split(' ')[0].toLowerCase()
                  ? 'selectedItem'
                  : ''
              }`}>
              <Text>{dashboardName}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};
