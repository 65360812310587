import { Flex } from '@chakra-ui/react';
import { goBack } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumbsComponent } from '../../components/breadcrumbs/breadcrumbsComponent';
import { Form } from './form';
import { saveDictionaryRow } from '../../actions/dictionary-values';
import { styles } from './dictionary-styles';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { ModalComponent } from '../../components/Modal/modal';
import { Action } from 'redux';

type DictionaryValueDetailProps = {
  addRow: boolean;
};

const determineFormDefinition = (
  rowForm: CMxComponents.FormDefintion,
  selectedDictionary: CMxAPI.Dictionary,
  mappedColValues?: CMx.mappedColValues[]
) => {
  if (mappedColValues && mappedColValues.length < 1) {
    return rowForm;
  } else if (rowForm.fieldGroups?.length && mappedColValues) {
    mappedColValues.forEach(mappedColValue => {
      let matchedColDef = selectedDictionary.columnDefinitions.find(
        colDef =>
          colDef.cellReferences[0]?.lookupProperty === mappedColValue?.key
      );

      // skip if this column has text editable overwrite
      if (!matchedColDef?.cellReferences[0]?.textEditable) {
        // getting non-metadata fields
        let fields: CMxComponents.FieldDefinition[] = rowForm.fieldGroups![
          rowForm.fieldGroups!.length - 1
        ].fields;

        let matchedFieldDefinition = fields.find(
          (field: CMxComponents.FieldDefinition) =>
            field.label === matchedColDef?.name
        );
        const fieldDefinitionLabel = matchedFieldDefinition?.label ?? '';
        if (matchedFieldDefinition && rowForm.fieldGroups) {
          let newFormDefinition: CMxComponents.FieldDefinition = {
            label: fieldDefinitionLabel,
            key: fieldDefinitionLabel,
            name: fieldDefinitionLabel,
            required: true,
            placeholder: 'Choose ' + fieldDefinitionLabel,
            helpText: 'Select a value from mapped dictionary.',
            type: 'select',
            options: mappedColValue.values.map(cell => ({
              label: cell.value,
              value: cell.value
            })),
            labelField: 'label',
            valueField: 'value',
            readonly: false
          };
          rowForm.fieldGroups[
            rowForm.fieldGroups?.length - 1
          ].fields = fields.map(field =>
            field.label === newFormDefinition.label ? newFormDefinition : field
          );
        }
      }
    });
  }

  return rowForm;
};

const DictionaryValueDetail: React.FC<DictionaryValueDetailProps> = props => {
  const { addRow } = props;

  const classes = styles();
  const dispatch = useDispatch();

  const item = useSelector((appState: CMx.ApplicationState) => {
    const index = appState.dictionaryValues.selectedRowIndex;
    return (
      appState.dictionaryValues.items.find(item => item.rowIndex === index) ||
      {}
    );
  });

  const items = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.items;
  });

  const mappedColValues = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.mappedColValues;
  });

  const selectedDictionary = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.selectedDictionary;
  });

  const rowEditForm = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.rowEditForm;
  });

  const rowAddForm = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.rowAddForm;
  });

  const [rowItem, setRowItem] = useState(addRow ? {} : item);
  const [duplicateKeyMessage, setDuplicateKeyMessage] = useState<boolean>(
    false
  );
  const [rowFormDefinition, setRowFormDefinition] = useState<
    CMxComponents.FormDefintion
  >(
    determineFormDefinition(
      addRow ? rowAddForm : rowEditForm,
      selectedDictionary,
      mappedColValues
    )
  );

  useEffect(() => {
    setRowFormDefinition(
      determineFormDefinition(
        addRow ? rowAddForm : rowEditForm,
        selectedDictionary,
        mappedColValues
      )
    );
  }, [mappedColValues, rowAddForm, rowEditForm, addRow, selectedDictionary]);

  const getMaxRowIndex = () => {
    if (items.length > 0) {
      const maxItem = items.reduce((prev, current) =>
        prev && prev.rowIndex > current.rowIndex ? prev : current
      );
      return maxItem.rowIndex;
    }
    return 0;
  };

  const handleAction = () => {
    dispatch(goBack());
  };

  const handleSave = (row: any) => {
    const cells: CMxAPI.CellDto[] = [];
    let payload: CMxAPI.DictionaryRowDto = {
      id: addRow ? null : row.id,
      tenantId: selectedDictionary.tenantId.uuid,
      rowIndex: addRow ? getMaxRowIndex() + 1 : row.rowIndex,
      status: selectedDictionary.status,
      editStatus: selectedDictionary.editStatus,
      effectiveDate: selectedDictionary.effectiveDate,
      endDate: selectedDictionary.endDate,
      effectiveDateLocal: null,
      endDateLocal: null,
      dictionaryId: selectedDictionary.id,
      dictionaryName: selectedDictionary.name,
      dictionary: selectedDictionary,
      cells: cells
    };
    selectedDictionary.columnDefinitions.forEach(colDef =>
      cells.push({
        id: row[colDef.name + 'Id'],
        tenantId: selectedDictionary.tenantId.uuid,
        value: row[colDef.name],
        codeType: '',
        status: selectedDictionary.status,
        editStatus: selectedDictionary.editStatus,
        columnDefinition: colDef,
        columnDefinitionId: colDef.id,
        rowId: row.id,
        rowIndex: row.rowIndex,
        columnName: colDef.name,
        effectiveDate: selectedDictionary.effectiveDate,
        endDate: selectedDictionary.endDate,
        effectiveDateLocal: null,
        endDateLocal: null,
        row: null
      })
    );

    if (addRow) {
      const keyColumn = selectedDictionary.columnDefinitions.find(
        colDef => colDef.keyColumnIndicator
      );
      const keyColumnId = keyColumn?.id;
      const keyColumnName = keyColumn?.name;
      const newKeyValue = cells.find(
        cell => cell.columnDefinitionId === keyColumnId
      )?.value;
      if (keyColumnName) {
        const keys = items.map(item => item[keyColumnName]);
        if (newKeyValue && keys.includes(newKeyValue)) {
          setDuplicateKeyMessage(true);
          return;
        }
      }
    }

    dispatch((saveDictionaryRow(payload) as any) as Action);
    dispatch(goBack());
  };

  const handleFormChange = (prop: string, value: string) => {
    let rowItemToUpdate = { ...rowItem };
    rowItemToUpdate[prop] = value;
    setRowItem(rowItemToUpdate);
  };

  const enabler = () => {
    return true;
  };

  return (
    <>
      <Flex marginBottom={5}>
        <BreadCrumbsComponent />
      </Flex>
      <Form
        formDefinition={rowFormDefinition}
        dataItem={rowItem}
        inline={false}
        enable={enabler}
        onAction={handleAction}
        onPropChange={handleFormChange}
        onSave={() => handleSave(rowItem)}
        title={addRow ? 'Add Row' : 'Edit Dictionary'}
        errors={{
          status: '',
          message: null,
          errors: [],
          fieldErrors: {}
        }}
      />
      <ModalComponent
        size={'s'}
        isOpen={duplicateKeyMessage}
        handleClose={() => setDuplicateKeyMessage(false)}
        showCloseIcon={true}
        modalHeader={
          <Flex sx={classes['duplicateKeyErrorHeader']}>
            <WarningTwoIcon /> Add Row Error
          </Flex>
        }
        modalContent={
          <div>
            Entered key column already exists in this dictionary. Please enter a
            different value.
          </div>
        }></ModalComponent>
    </>
  );
};

DictionaryValueDetail.displayName = 'DictionaryValueDetail';
export { DictionaryValueDetail };
